const en = {

    auth: {
        vi: "Vietnamese",
        en: "English",
        username: "Username",
        password: "Password",
        confirmPassword: "Confirm Password",
        login: "Login",
        or: "Or",
        register: "Register",
        forgotPassword: "Forgot PassWord",
        firstName: "First Name",
        lastName: "Last Name",
        gender: "Gender",
        female: "Female",
        male: "Male",
        other: "Other",
        phone: "Phone",
        email: "Email",
        firstNameError: "Please enter your firstName",
        lastNameError: "Please enter your lastName",
        usernameError: "Please enter your username",
        passwordError: "Please enter your password",
        confirmPasswordError: "Password not match",
        phoneError: "Please enter valid mobile number",
        emailError: "Please enter valid email",
        registerSuccess: " Register Succesfully",
        updateProfile: "Save Changes",
        updateProfileSuccess: "Save Changes Succesfully",
        profile: "Profile",
        settings: "Settings",
        logout: "Logout",
        accountSetting: "Account Settings",
        you: "You",
        language: "Language",
        loginError: "Username or Password Incorrect",
        lastFileSend: "{{sender}} have sent {{number}} {{fileType}}",
        image: "image",
        file: "file",
        video: "video",
        onlineStatus: 'online',
        confirm: 'confirm',
        sent: 'sent'
    },

    chat: {
        listChat: "List Chat",
        searchDescription: "Find on Messenger",
        searchUserDescription: "Find on Messenger",
    }

}

export default en;
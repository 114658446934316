import messLogo from './MessLogo.png'
import en from './englandFlag.jpg'
import vi from './vietnamFlag.png'
import like from './like.png'
import love from './love.png'
import sad from './sad.png'
import angry from './angry.png'
import graySmile from './gray_smile.png'

const images = {
    messLogo,
    en,
    vi,
    like,
    love,
    sad,
    angry,
    graySmile,
}

export default images
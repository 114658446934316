export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGIN_DONE = 'CHECK_LOGIN_DONE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_DONE = 'LOGOUT_DONE';

export const GET_USERINFO = 'GET_USERINFO';
export const GET_USERINFO_SUCCESS = 'GET_USERINFO_SUCCESS';
export const GET_USERINFO_FAILED = 'GET_USERINFO_FAILED';

export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_USERINFO_SUCCESS = 'UPDATE_USERINFO_SUCCESS';
export const UPDATE_USERINFO_FAILED = 'UPDATE_USERINFO_FAILED';

export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILED = 'SEARCH_USER_FAILED';

export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS';
export const REGISTER_ACCOUNT_FAILED = 'REGISTER_ACCOUNT_FAILED';

export const GET_CONVERSATIONS = 'GET_CONVERSATIONS';
export const GET_CONVERSATIONS_SUCCESS = 'GET_CONVERSATIONS_SUCCESS';
export const GET_CONVERSATIONS_FAILED = 'GET_CONVERSATIONS_FAILED';

export const CREATE_CONVERSATIONS = 'CREATE_CONVERSATIONS';
export const CREATE_CONVERSATIONS_SUCCESS = 'CREATE_CONVERSATIONS_SUCCESS';
export const CREATE_CONVERSATIONS_FAILED = 'CREATE_CONVERSATIONS_FAILED';

export const UPDATE_GROUPCHAT = 'UPDATE_GROUPCHAT';
export const UPDATE_GROUPCHAT_SUCCESS = 'UPDATE_GROUPCHAT_SUCCESS';
export const UPDATE_GROUPCHAT_FAILED = 'UPDATE_GROUPCHAT_FAILED';

export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';

export const SEARCHCONVERSATIONS = 'SEARCHCONVERSATIONS';
export const SEARCHCONVERSATIONS_SUCCESS = 'SEARCHCONVERSATIONS_SUCCESS';
export const SEARCHCONVERSATIONS_FAILED = 'SEARCHCONVERSATIONS_FAILED';

export const GET_CONVERSATIONID = 'GET_CONVERSATIONID';
export const GET_CONVERSATIONID_SUCCESS = 'GET_CONVERSATIONID_SUCCESS';
export const GET_CONVERSATIONID_FAILED = 'GET_CONVERSATIONID_FAILED';

export const NEW_CHAT = 'NEW_CHAT';
export const NEW_CHAT_SUCCESS = 'NEW_CHAT_SUCCESS';
export const NEW_CHAT_FAILED = 'NEW_CHAT_FAILED';

export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';

export const SEND_MESSAGES = 'SEND_MESSAGES';
export const SEND_MESSAGES_SUCCESS = 'SEND_MESSAGES_SUCCESS';
export const SEND_MESSAGES_FAILED = 'SEND_MESSAGES_FAILED';

export const RESET_MESSAGES = 'RESET_MESSAGES';
export const RESET_MESSAGES_SUCCESS = 'RESET_MESSAGES_SUCCESS';
export const RESET_MESSAGES_FAILED = 'RESET_MESSAGES_FAILED';

export const READ_MESSAGE = 'READ_MESSAGE';
export const READ_MESSAGE_SUCCESS = 'READ_MESSAGE_SUCCESS';

export const UPDATE_REACTION = 'UPDATE_REACTION';
export const UPDATE_REACTION_SUCCESS = 'UPDATE_REACTION_SUCCESS';
export const UPDATE_REACTION_FAIL = 'UPDATE_REACTION_FAIL';

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const SHOW_REACTION_POPUP = 'SHOW_REACTION_POPUP';


const vi = {
    listfriend: "Danh Sách Bạn",
    auth: {
        vi: "Tiếng Việt",
        en: "Tiếng Anh",
        username: "Tên Tài Khoản",
        password: "Mật Khẩu",
        confirmPassword: "Xác Nhận Mật Khẩu",
        login: "Đăng Nhập",
        or: "Hoặc",
        register: "Đăng Kí",
        forgotPassword: "Quên Mật Khẩu",
        firstName: "Tên",
        lastName: "Họ",
        gender: "Giới Tính",
        female: "Nữ",
        male: "Nam",
        other: "Giới tính khác",
        phone: "Số Điện Thoại",
        email: "Thư Điện Tử",
        firstNameError: "Vui lòng nhập họ của bạn ",
        lastNameError: "Vui lòng nhập tên của bạn ",
        usernameError: "Vui lòng nhập tên đăng nhập của bạn ",
        passwordError: "Vui lòng nhập mật khẩu của bạn ",
        confirmPasswordError: "Mật khẩu không khớp",
        phoneError: "Vui lòng nhập số điện thoại hợp lệ",
        emailError: "Vui lòng nhập thư điện tử hợp lệ ",
        registerSuccess: "Đăng kí thành công",
        updateProfile: "Lưu Thay Đổi",
        updateProfileSuccess: "Lưu Thay Đổi Thành Công",
        profile: "Hồ Sơ",
        settings: "Cài Đặt",
        logout: "Đăng Xuất",
        accountSetting: "Cài Đặt Tài Khoản",
        you: "Bạn",
        language: "Ngôn Ngữ",
        loginError: "Sai Tên Tài Khoản Hoặc Mật Khẩu",
        lastFileSend: "{{sender}} đã gửi {{number}} {{fileType}}",
        image: "ảnh",
        file: "tệp",
        video: "video",
        onlineStatus: 'Đang Hoạt Động',
        confirm: 'Xác Nhận',
        sent: 'Đã gửi tin nhắn'
    },

    chat: {
        listChat: "Danh Sách Trò Chuyện",
        searchDescription: "Tìm kiếm trên Messenger",
    }
}

export default vi;
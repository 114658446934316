import * as React from 'react';
import { io } from "socket.io-client";
import axios from 'axios';

import HomePage from "../Pages/HomePage";
import MenuPage from "../Pages/MenuPage";
import LoginPage from "../Pages/LoginPage";
import RegisterPage from "../Pages/RegisterPage";
import ProfilePage from "../Pages/ProfilePage";


export const routes = {
    MenuPage: {
        path: '/Menu',
        exact: true,
        component: MenuPage
    },
    ProfilePage: {
        path: '/ProfilePage',
        exact: true,
        component: ProfilePage
    },
    HOME: (id) => {
        let path = '/:id?'

        if (id !== undefined) {
            path = `/${id}`
        }
        return {
            path,
            component: HomePage
        }
    },
}

export const publicRoutes = {
    LoginPage: {
        path: '/',
        exact: true,
        component: LoginPage
    },
    RegisterPage: {
        path: '/Register',
        exact: true,
        component: RegisterPage
    },
}

export const host = process.env.REACT_APP_API_HOST;

export const axiosClient = axios.create({
    baseURL: `${host}/api`
});

export const localStorageKey = {
    token: "token",
    language: "",
}

export const FileTypes = {
    CHAT_IMAGE: 'CHAT_IMAGE',
    CHAT_VIDEO: 'CHAT_VIDEO',
    CHAT_FILE: 'CHAT_FILE',
    USER_AVATAR: 'USER_AVATAR',
};

export const messageTypes = {
    MESSAGE: 'MESSAGE',
    LIKE: 'LIKE',
};

export const newChat = 'newChat'

export const reactApp = 'React App'

export const SocketEvents = {
    NEW_MESSAGE: 'new-message',
    NEW_CONVERSATION: 'new-conversation',
    READ_MESSAGE: 'read-message',
    UPDATE_MESSAGE: 'update-message',
};

export const fullScreenImageRef = React.createRef()

export const appSnackBarRef = React.createRef()

export const socket = io(host, {
    autoConnect: false
});

export const REACTION_TYPES = {
    LIKE: 'LIKE',
    LOVE: 'LOVE',
    ANGRY: 'ANGRY',
    SAD: 'SAD',
}

export const onlyEmojiRegExp = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])+$/m